













































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import VERSICHERUNGSSUMMENSTAFFEL_SHOW from '@/config/MKZ-versicherungssummenstaffel-show-values';
import ANZAHL_DER_GERATE_SHOW from '@/config/MKZ-Anzahl-der-Gerate-show-values';
import { copyObject,
  downloadFile,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  unselectFirstElement } from '@/helper/functions';
import { PHOTOVOLTAIK_INSURANCE } from '@/constant/constants';
import priceStatusIcon from '@/components/form/price-status-icon.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import apiBackend from '@/service/api-insurance';
import VersicherungsortRow from '@/views/components/photovoltaics-insurance/versicherungsort-row.vue';

const versicherungsortItem = {
  isSelected: false,
  num: 0,
  Strabe: '',
  houseNum: '',
  PLZ: '',
  Ort: '',
  Land: '',
  ZursZone: '',
  Starkregenzone: '',
  Naturgefahren2: '',
  Naturgefahren3: '',
};
const versicherungsortType = 1;

const DeckungsanpassungenJeRisikoItem = {
  open: true,
  FeuerAusschlussGemabTK1232: 'nein',
  LeitungswasserAusschlussGemabTK1233: 'nein',
  EinbruchdiebstahlAusschlussGemabTK1234: 'nein',
  DiebstahlAusschlussGemabTK1235: 'nein',
  AusschlussUberschwemmung: 'nein',
  AusschlussSturm: 'nein',
  GrenzeDerEntschadigungGemabTK1722: 'nein',
  GrenzeDerEntschadigung: null,
  Nachlass: null,
  Erdbeben: '',
  ErdbebenSelbstbeteiligung: null,
  JHE: null,
  InnereUnruhenMitversicherungGemabTK1236: 'nein',
  ErweiteterGeltungsbereichGemabTK1408: '',
  Geltungsbereich: '',
  RegressverzichtGemabTK1820: '',
  BU: '',
  MehrkostenGemabTK1930: '',
  ZeitabhangigeMehrkosten: '',
  Freitext: '',
  ZeitlicheSelbstbeteiligung: '',
  Haftzeit: '',
  ZeitunabhangigeMehrkosten: '',
  Selbstbeteiligung: null,
  SelbstbeteiligungPercentage: null,
  SelbstbeteiligungMin: null,
  SelbstbeteiligungMax: null,
  Haftzeit2: '',
  BeitragFurDieMitversicherungTK1930: null,
  SelbstbeteiligungPercentageTk1408: 25,
};
const deckungsanpassungenJeRisikoType = 2;

const erstrisikosummenAdditionalItem = {
  name: '',
  ERS: null,
  GeanderteERS: null,
};
const erstrisikosummenType = 3;

export default Vue.extend({
  name: 'photovoltaics-insurance-EEPV',
  mixins: [dataChangeMixin],
  components: {
    priceStatusIcon,
    VersicherungsortRow,
  },
  data() {
    return {
      name: 'photovoltaics-insurance-EEPV',
      key: PHOTOVOLTAIK_INSURANCE,
      backUrl: 'photovoltaics-basic',
      submitedForm: false,
      nextTabId: 3,
      versicherungsortItems: [
        {
          ...versicherungsortItem,
        },
      ],
      byVersicherungsortType: versicherungsortType,
      byDeckungsanpassungenJeRisikoType: deckungsanpassungenJeRisikoType,
      Positionsdaten: {
        Gerateart: '',
        Beschreibung: '',
        MKZ: null,
        Bewertungsjahr: '',
        Versicherungssumme: '',
        TK1507AngleichungVNr: '',
        Geltungsbereich: '',
        Selbstbeteiligung: '',
        versicherungssummenstaffel: '',
        anzahlDerGerate: '',
        Sondervereinbarungen: '',
        DeckungsanpassungenJeRisikoItems: [
          {
            ...DeckungsanpassungenJeRisikoItem,
          },
        ],
      },
      MitversicherungSoftwareversicherungDatenversicherung: {
        select: '',
        Versicherungssumme: null,
        AuslagerungInEinenAnderenGefahrenFeuerbereich: '',
        LagerungDerDatenInDatensicherheitsschrank: '',
      },
      SelbstbeteiligungHardware: {
        tarif: {
          name: 'Tarif-SB',
          SB: null,
          SBRabattfaktor: null,
          TarifpramiensatzAktuellNetto: null,
          Tarifpramiensatz371Netto: null,
          TarifpramieNetto: null,
        },
        variant1: {
          name: 'SB-Variante 1',
          SB: null,
          SBRabattfaktor: null,
          TarifpramiensatzAktuellNetto: null,
          Tarifpramiensatz371Netto: null,
          TarifpramieNetto: null,
        },
        variant2: {
          name: 'SB-Variante 2',
          SB: null,
          SBRabattfaktor: null,
          TarifpramiensatzAktuellNetto: null,
          Tarifpramiensatz371Netto: null,
          TarifpramieNetto: null,
        },
        variant3: {
          name: 'SB-Variante 3',
          SB: null,
          SBRabattfaktor: null,
          TarifpramiensatzAktuellNetto: null,
          Tarifpramiensatz371Netto: null,
          TarifpramieNetto: null,
        },
      },
      erstrisikosummen: {
        BaugrundBodenmassen: {
          name: 'Aufräumungs-, Bergungs-, Dekontaminations- und Entsorgungskosten',
          ERS: null,
          GeanderteERS: null,
        },
        HilfsbautenUndBauhilfsstoffe: {
          name: 'Dekontaminations- und Entsorgungskosten für Erdreich',
          ERS: null,
          GeanderteERS: null,
        },
        ArbeitsUndFasadengeruste: {
          name: 'Bewegungs- und Schutzkosten',
          ERS: null,
          GeanderteERS: null,
        },
        BautafelERS: {
          name: 'Luftfrachtkosten',
          ERS: null,
          GeanderteERS: null,
        },
        LokalisierungSchadenurs: {
          name: 'Bergungskosten',
          ERS: null,
          GeanderteERS: null,
        },
        ZusatzlicheAufraumkosten: {
          name: 'Kosten für Erd-, Pflaster-, Mauer- und Stemmarbeiten',
          ERS: null,
          GeanderteERS: null,
        },
      },
      erstrisikosummenAdditionalItems: [] as any,
      byErstrisikosummenType: erstrisikosummenType,
      notizen: '',
      pdfStyles: false,
      refImportFileName: 'excelImport',
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id', 'productId', 'processSavingData', 'priceData'],
  watch: {
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.basicData && this.firstOpenTab) {
        this.fillVersicherungsortItemsFromGruppendaten(this.insuranceData.basicData.versicherungsort);
      }
    },
  },
  methods: {
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 1);
    },
    fillVersicherungsortItemsFromGruppendaten(versicherungsort: any) {
      if (!this.versicherungsortItems[0].Strabe) {
        this.versicherungsortItems[0].Strabe = versicherungsort.Strabe;
      }

      if (!this.versicherungsortItems[0].PLZ) {
        this.versicherungsortItems[0].PLZ = versicherungsort.PLZ;
      }

      if (!this.versicherungsortItems[0].Ort) {
        this.versicherungsortItems[0].Ort = versicherungsort.Ort;
      }
      if (!this.versicherungsortItems[0].houseNum) {
        this.versicherungsortItems[0].houseNum = versicherungsort.Nr;
      }

      this.versicherungsortItems[0].ZursZone = versicherungsort.ZursZone;
      this.versicherungsortItems[0].Starkregenzone = versicherungsort.Starkregenzone;
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Photovoltaics-EEPV-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    deleteErstrisikosummenItem(key) {
      this.erstrisikosummenAdditionalItems.splice(key, 1);
    },
    dublicateRow(fillData: boolean, type: number) {
      switch (type) {
        case this.byVersicherungsortType:
          duplicateDataItems(versicherungsortItem, this.versicherungsortItems, fillData);
          break;
        case this.byDeckungsanpassungenJeRisikoType:
          duplicateDataItemsWithoutSelect(DeckungsanpassungenJeRisikoItem, this.Positionsdaten.DeckungsanpassungenJeRisikoItems);
          break;
        case this.byErstrisikosummenType:
          duplicateDataItemsWithoutSelect(erstrisikosummenAdditionalItem, this.erstrisikosummenAdditionalItems);
          break;
        default:
          console.log('Wrong type');
      }
    },
    setValues(insuranceData: any) {
      const calculationData = insuranceData;
      this.versicherungsortItems = calculationData.versicherungsortItems;
      this.Positionsdaten = calculationData.Positionsdaten;
      this.MitversicherungSoftwareversicherungDatenversicherung = calculationData.MitversicherungSoftwareversicherungDatenversicherung;
      this.SelbstbeteiligungHardware = calculationData.SelbstbeteiligungHardware;
      this.erstrisikosummen = calculationData.erstrisikosummen;
      this.erstrisikosummenAdditionalItems = calculationData.erstrisikosummenAdditionalItems;
      this.notizen = calculationData.notizen;
    },
    onSubmit() {
      this.submitForm();
    },
    submitForm(goNextPage = true) {
      const data = {
        versicherungsortItems: this.versicherungsortItems,
        Positionsdaten: this.Positionsdaten,
        MitversicherungSoftwareversicherungDatenversicherung: this.MitversicherungSoftwareversicherungDatenversicherung,
        SelbstbeteiligungHardware: this.SelbstbeteiligungHardware,
        erstrisikosummen: this.erstrisikosummen,
        erstrisikosummenAdditionalItems: this.erstrisikosummenAdditionalItems,
        notizen: this.notizen,
      };

      this.$emit('insurance-forms-data-change', data, 'eepv', goNextPage ? this.nextTabId : false);

      this.submitedForm = true;
    },
    deleteRows(type: number) {
      let items;

      switch (type) {
        case this.byVersicherungsortType:
          unselectFirstElement(this.versicherungsortItems);

          items = this.versicherungsortItems.filter((item) => !item.isSelected);
          this.versicherungsortItems = items;
          break;
        default:
          console.log('Wrong type');
      }
    },
    chooseImportFile() {
      this.excelImportRef.click();
    },
    uploadFile(element) {
      const file = element.target.files[0];

      if (file) {
        apiBackend.uploadFile(`${this.id}/import-places-excel`, 'import', file).then((response: any) => {
          this.importPositionsdatenFromExcel(response.data);
          this.excelImportRef.value = null;
        }).catch((e) => {
          console.error(`Error - ${e}`);
        });
      }
    },
    importPositionsdatenFromExcel(dataItems: any[]) {
      const firstElementEmpty = this.versicherungsortItems.length === 1 &&
        !this.versicherungsortItems[0].Strabe &&
        !this.versicherungsortItems[0].PLZ &&
        !this.versicherungsortItems[0].Ort &&
        !this.versicherungsortItems[0].Land;

      dataItems.forEach((item, i) => {
        const fillFirst = firstElementEmpty && i === 0;

        const newItem = fillFirst ? this.versicherungsortItems[0] : copyObject(versicherungsortItem);

        newItem.Strabe = item.street;
        newItem.PLZ = item.postCode;
        newItem.Ort = item.place;
        newItem.Land = item.land;

        if (!fillFirst) {
          this.versicherungsortItems.push(newItem);
        }
      });
    },
    downloadInsurancePlacesTemplate() {
      apiBackend.downloadInsurancePlaces().then((response) => {
        downloadFile(response.data, 'Einlesen von Versicherungsorten.xlsx');
      });
    },
    exportPlacesData() {
      const data = this.versicherungsortItems.map((itm) => ({
        street: itm.Strabe,
        code: `${itm.PLZ}`,
        city: itm.Ort,
        land: itm.Land,
      }));

      apiBackend.exportPlacesData(data).then((response) => {
        downloadFile(response.data, 'Versicherungsort-EEPV nach ABE .xlsx');
      });
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData && this.insuranceData.insuranceFormsData && this.insuranceData.insuranceFormsData.eepv) {
      this.setValues(this.insuranceData.insuranceFormsData.eepv);
    }
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (!this.isPCUser && !this.submitedForm) {
      this.submitForm(false);
    }
  },
  computed: {
    showPlusBtn(): boolean {
      return (this.erstrisikosummenAdditionalItems.length < 5);
    },
    showMinusBtn(): boolean {
      return (this.erstrisikosummenAdditionalItems.length > 1);
    },
    firstOpenTab(): boolean {
      // eslint-disable-next-line no-prototype-builtins
      return (this.insuranceData === null || this.insuranceData.insuranceFormsData === null || !this.insuranceData.insuranceFormsData.hasOwnProperty('eepv'));
    },
    versicherungssummenstaffelShow() {
      if (VERSICHERUNGSSUMMENSTAFFEL_SHOW.some((e) => e === this.Positionsdaten.MKZ)) {
        return true;
      }
      return false;
    },
    anzahlDerGerateShow() {
      if (ANZAHL_DER_GERATE_SHOW.some((e) => e === this.Positionsdaten.MKZ)) {
        return true;
      }
      return false;
    },
    positionsdatenColspan() {
      let result = 4;
      if (this.versicherungssummenstaffelShow) {
        result -= 1;
      }
      if (this.anzahlDerGerateShow) {
        result -= 1;
      }
      return result;
    },
    excelImportRef(): any {
      return this.$refs[this.refImportFileName];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
});
